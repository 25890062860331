export const searchForm = [
  {
    label: '企业名称',
    key: 'projectName'
  },
  {
    label: '推送状态',
    key: 'wholeStatus',
    type: 'select',
    options: [{ label: '未反馈', value: 2 }, { label: '成功', value: 3 }, { label: '失败', value: 4 }]
  }
]

export const reportSearchForm = [
  {
    label: '报告编号',
    key: 'reportCode'
  },
  {
    label: '客户名称',
    key: 'checkProjectName'
  }
]

export const contractInfoModel = [
  {
    label: '合同名称',
    keys: ['contractName']
  },
  {
    label: '合同编号',
    keys: ['num']
  },
  {
    label: '区域',
    keys: ['province', 'city', 'district']
  },
  {
    label: '合同附件',
    keys: ['filePath'],
    type: 'download'
  }
]

export const contractContentModel = [
  {
    label: '甲方',
    keys: ['checkProjectName']
  },
  {
    label: '乙方',
    keys: ['projectName']
  },
  {
    label: '项目地址',
    keys: ['address']
  },
  {
    label: '建筑面积',
    keys: ['acreage'],
    unit: 'm²',
    type: 'thousandNumber'
  },
  {
    label: '建筑高度',
    keys: ['height'],
    unit: 'm',
    type: 'number'
  },
  {
    label: '建筑层数',
    keys: ['floorNumber'],
    unit: '层'
  },
  {
    label: '使用性质',
    keys: ['quality']
  }
]

export const contractUserModel = [
  {
    label: '技术负责人',
    keys: ['technologyLeaderNameStrs']
  },
  {
    label: '项目负责人',
    keys: ['projectPersonNameStrs']
  }
]

export const firstConferenceModel = [
  {
    label: '客户名称',
    keys: ['checkProjectName'],
    full: true
  },
  {
    label: '会议标题',
    keys: ['title']
  },
  {
    label: '会议日期',
    keys: ['conferenceDate']
  },
  {
    label: '会议内容',
    keys: ['content'],
    full: true
  },
  {
    label: '签到表',
    keys: ['signForm'],
    type: 'download',
    valueText: '签到表下载',
    full: true
  },
  {
    label: '会议门面照片',
    keys: ['facadePhotos'],
    type: 'image'
  },
  {
    label: '会议现场照片',
    keys: ['sitePhotos'],
    type: 'image'
  },
  {
    label: '人员签到照片',
    keys: ['signPhotos'],
    type: 'image'
  },
  {
    label: '会议照片',
    keys: ['conferencePhotos'],
    type: 'image'
  },
  {
    label: '原始记录',
    keys: ['sourceRecord'],
    type: 'download',
    valueText: '原始记录下载',
    full: true
  }
]

export const lastConferenceModel = [
  {
    label: '会议标题',
    keys: ['title1']
  },
  {
    label: '会议日期',
    keys: ['conferenceDate1']
  },
  {
    label: '会议内容',
    keys: ['content1'],
    full: true
  },
  {
    label: '签到表',
    keys: ['signForm1'],
    type: 'download',
    valueText: '签到表下载',
    full: true
  },
  {
    label: '会议门面照片',
    keys: ['facadePhotos1'],
    type: 'image'
  },
  {
    label: '会议现场照片',
    keys: ['sitePhotos1'],
    type: 'image'
  },
  {
    label: '人员签到照片',
    keys: ['signPhotos1'],
    type: 'image'
  },
  {
    label: '会议照片',
    keys: ['conferencePhotos1'],
    type: 'image'
  },
  {
    label: '其它附件',
    keys: ['otherAttachment'],
    type: 'download',
    valueText: '其它附件下载',
    full: true
  },
  {
    label: '单项报告',
    keys: ['singleReport'],
    type: 'download',
    valueText: '单项报告下载',
    full: true
  }
]
