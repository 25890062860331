<template>
  <el-dialog v-model="dialogFormVisible" title="技术服务机构配置信息详情">
    <el-form :model="form">
      <h3>全国社会消防技术服务信息系统公司账户信息</h3>
      <el-form-item label="账号：">
        <el-input v-model="form.coAccount">
          <!-- <template #append>
            <el-button @click="copy(form.coAccount)">复制</el-button>
          </template> -->
        </el-input>
      </el-form-item>
      <el-form-item label="密码：">
        <el-input v-model="form.coPassword">
           <!-- <template #append>
            <el-button @click="copy(form.coPassword)">复制</el-button>
          </template> -->
        </el-input>
      </el-form-item>
      <h3>项目负责人账户信息</h3>
      <el-form-item label="账号：">
        <el-input v-model="form.projectAccount">
           <!-- <template #append>
            <el-button @click="copy(form.projectAccount)">复制</el-button>
          </template> -->
        </el-input>
      </el-form-item>
      <el-form-item label="密码：">
        <el-input v-model="form.projectPassword">
           <!-- <template #append>
            <el-button @click="copy(form.projectPassword)">复制</el-button>
          </template> -->
        </el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineExpose, computed } from 'vue'
// import { copy } from '@/utils/copy'
const dialogFormVisible = ref(false)
defineExpose({
  dialogFormVisible
})
const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => ({})
  }
})

const form = computed(() =>
  props.data
)
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
  padding-bottom: 20px;
}
</style>
