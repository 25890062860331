<template>
  <el-dialog v-model="dialogVisible" title="现场执业" @opened="handleOpened">
    <div v-if="empty" class="empty-box">没有会议数据</div>
    <template v-else>
      <h3>首次会议</h3>
      <el-row class="first-conference">
        <el-col
          v-for="item in firstConference"
          :key="item.label"
          :span="item.full ? 24 : 12"
          >{{ item.label }}：
          <el-button
            v-if="item.type === 'download'"
            type="text"
            @click="downloadFile(item.value, item.label)"
            >{{ item.valueText }}</el-button
          >
          <template v-else-if="item.type === 'image'">
            <el-image
              v-for="(img, index) in item.value"
              :key="img"
              style="width: 50px; height: 50px"
              :src="img"
              :preview-src-list="item.value"
              :initial-index="index"
            />
          </template>
          <template v-else
            >{{ item.valueUnit }}

            <!-- <el-button class="copy-btn" size="mini" @click="copy(item.value)"
            >复制</el-button
          > -->
          </template></el-col
        >
      </el-row>
      <h3>末次会议</h3>
      <el-row class="last-conference">
        <template v-for="item in lastConference" :key="item.label">
          <el-col
            v-if="item.value && item.value !== 'undefined'"
            :span="item.full ? 24 : 12"
            >{{ item.label }}：
            <el-button
              v-if="item.type === 'download'"
              type="text"
              @click="downloadFile(item.value, item.label)"
              >{{ item.valueText }}</el-button
            >
            <template v-else-if="item.type === 'image'">
              <el-image
                v-for="(img, index) in item.value"
                :key="img"
                style="width: 50px; height: 50px"
                :src="img"
                :preview-src-list="item.value"
                :initial-index="index"
              />
            </template>
            <template v-else
              >{{ item.valueUnit }}

              <!-- <el-button class="copy-btn" size="mini" @click="copy(item.value)"
            >复制</el-button
          > -->
            </template></el-col
          >
        </template>
      </el-row>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineExpose } from 'vue'
// import { copy } from '@/utils/copy'
import { downloadFile, dataConversion } from '@/utils/common'
import { findConference } from '@/api/check-reports'
import { firstConferenceModel, lastConferenceModel } from '../data'

const dialogVisible = ref(false)
defineExpose({
  dialogVisible
})
const props = defineProps({
  report: {
    type: Object,
    required: true,
    default: () => ({})
  }
})

// watch(
//   () => props.report,
//   (value) => {
//     findReportConference(value)
//   }
// )

const handleOpened = () => {
  reset()
  findReportConference(props.report)
}

const firstConference = ref([])
const lastConference = ref([])
const empty = ref(false)
const findReportConference = async (item) => {
  const params = {
    checkProjectId: item.id,
    projectId: item.projectId
    // checkProjectId: item.checkProjectId
  }
  const { obj } = await findConference(params)
  if (!obj) {
    empty.value = true
    return
  } else {
    empty.value = false
  }
  const contract = obj || {}
  firstConference.value = firstConferenceModel.map(dataConversion(contract))
  lastConference.value = lastConferenceModel.map(dataConversion(contract))
}

const reset = () => {
  firstConference.value = []
  lastConference.value = []
}
</script>

<style lang="scss" scoped>
.el-col {
  min-height: 36px;
  vertical-align: middle;
  align-items: center;
  display: flex;
}
.el-row {
  padding-bottom: 15px;
}
h3 {
  font-size: 14px;
  border-left: 4px solid var(--el-color-primary);
  padding-left: 4px;
  background-color: #f1f1f1;
  line-height: 30px;
  margin-bottom: 10px;
}
.copy-btn {
  margin-left: 5px;
  padding: 0px 5px;
}
.el-form-item {
  margin-bottom: 0px;
  :deep(.el-form-item__label) {
    font-weight: 600;
    font-size: 15px;
  }
}

.first-conference {
  min-height: 303px;
}
.last-conference {
  min-height: 267px;
}

.empty-box {
  height: 650px;
  text-align: center;
  line-height: 650px;
}
</style>
