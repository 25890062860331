<template>
  <el-dialog
    v-model="dialogVisible"
    title="方案编制详情"
    width="450px"
    @opened="handleOpened"
  >
    <p>
      任务描述：<span>{{ contract.taskDesc }}</span>
    </p>
    <p>
      建筑高度：<span>{{ divide100(contract.height) }}m</span>
    </p>
    <p>
      单项负责人：<span>{{ contract.projectPersonNameStrs }}</span>
    </p>
    <!-- <p>
      执业周期：<span>{{ contract.period }}</span>
    </p> -->
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineExpose } from 'vue'
import { findContract } from '@/api/check-reports'
import { divide100 } from '@/utils/math'
const dialogVisible = ref(false)
defineExpose({
  dialogVisible
})
const props = defineProps({
  report: {
    type: Object,
    required: true,
    default: () => ({})
  }
})

// watch(
//   () => props.report,
//   (value) => {
//     findReportContract(value)
//   }
// )

const handleOpened = () => {
  reset()
  findReportContract(props.report)
}

// const period = computed(() =>
//   props.report.planName.includes('年度维保') ? '年' : '月'
// )

const contract = ref({})
const findReportContract = async (item) => {
  const params = {
    checkProjectId: item.id,
    projectId: item.projectId,
    // checkProjectId: item.checkProjectId,
    wholeStatus: item.wholeStatus
  }
  const { obj } = await findContract(params)
  contract.value = obj || {}
}

const reset = () => {
  contract.value = {}
}
</script>

<style lang="scss" scoped>
p {
  font-size: 14px;
  line-height: 50px;
  padding-left: 50px;
}
</style>
