<template>
  <div class="report-wrap">
    <h3 class="project-name">{{ projectName }}</h3>
    <form-search :form="reportSearchForm" @search="search" />
    <el-table
      :data="tableData"
      border
      stripe
      align="center"
      style="width: 100%"
    >
      <el-table-column type="index" width="50" align="center" label="序号" />
      <el-table-column prop="reportCode" align="center" label="报告编号">
        <template #default="scope">
          <span class="clickable" @click="downloadFile(scope.row.reportFileUrl, scope.row.reportCode)">{{ scope.row.reportCode }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="checkProjectName"
        align="center"
        label="客户名称"
      />
      <el-table-column align="center" label="账号配置信息" width="120">
        <template #default="scope">
          <el-button type="text" @click="showAccount(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="合同登记" width="100">
        <template #default="scope">
          <el-button type="text" @click="showContract(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="方案编制" width="100">
        <template #default="scope">
          <el-button type="text" @click="showProject(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="现场执业" width="100">
        <template #default="scope">
          <el-button type="text" @click="showConference(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="上传结果反馈" width="120">
        <template #default="scope">
          <el-button type="text" @click="showFeedback(scope.row)" :class="{'red-text': scope.row.wholeStatus === 4, 'green-text': scope.row.wholeStatus === 3}">{{
            wholeStatusToText(scope.row.wholeStatus)
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[15, 30, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    />
    <Feedback
      :report="selectedReport"
      ref="feedbackComponent"
      @success="loadTableData"
    />
    <Account ref="accountComponent" :data="accountForm" />
    <Project ref="projectComponent" :report="selectedReport" />
    <Contract ref="contractComponent" :report="selectedReport" />
    <Conference ref="conferenceComponent" :report="selectedReport" />
  </div>
</template>

<script setup>
import { ref, watch, onActivated } from 'vue'
import FormSearch from '@/components/FormSearch'
import { reportSearchForm } from './data'
import { findReportList } from '@/api/check-reports'
import { tableSearchPagination } from '@/utils/table'
import { downloadFile } from '@/utils/common'
import { useRoute } from 'vue-router'
import Feedback from './components/Feedback'
import Account from './components/Account'
import Project from './components/Project'
import Contract from './components/Contract'
import Conference from './components/Conference'
const route = useRoute()
const projectName = ref(route.query.projectName)

const {
  pageIndex,
  pageSize,
  handleSizeChange,
  handleCurrentChange,
  total,
  search,
  searchPaginationParams
} = tableSearchPagination()

watch(searchPaginationParams, () => {
  loadTableData()
})

onActivated(() => {
  projectName.value = route.query.projectName
  loadTableData()
})

const selectedReport = ref({})
/**
 * 表格数据
 */
const tableData = ref([])
const loadTableData = async () => {
  const result = await findReportList({
    ...searchPaginationParams.value,
    orderCols: ['createTime'],
    orderDesc: true,
    projectId: +route.query.checkProjectId
  })
  tableData.value = result.listObj
  total.value = result.totalCount
}

/**
 * 查看账号配置信息
 */
const accountForm = ref({})
const accountComponent = ref(null)
const showAccount = (report) => {
  accountForm.value = report
  accountComponent.value.dialogFormVisible = true
}

const contractComponent = ref(null)
const showContract = (item) => {
  selectedReport.value = item
  contractComponent.value.dialogVisible = true
}

const feedbackComponent = ref(null)
const showFeedback = (item) => {
  selectedReport.value = item
  feedbackComponent.value.dialogFormVisible = true
}

const projectComponent = ref(null)
const showProject = (item) => {
  selectedReport.value = item
  projectComponent.value.dialogVisible = true
}

const conferenceComponent = ref(null)
const showConference = (item) => {
  selectedReport.value = item
  conferenceComponent.value.dialogVisible = true
}

const wholeStatusToText = (status) => {
  switch (status) {
    case 3:
      return '上传成功'
    case 4:
      return '上传失败'
    default:
      return '未反馈'
  }
}
</script>

<style lang="scss" scoped>
.el-table :deep(thead th) {
  background-color: #eff4fa;
}
.el-pagination {
  margin-top: 20px;
}
.project-name {
  font-size: 16px;
  padding-bottom: 20px;
}
</style>
